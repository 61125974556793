import React, { useState } from 'react';
import { Select } from './Select.jsx'
import { SalesTable } from './SalesTable.jsx';
import { ModalEdit } from './ModalEdit.jsx';
import { ModalEntry } from './ModalEntry.jsx';
import { LedgerSelect } from './LedgerSelect.jsx';
import { PurchaseTable } from './PurchaseTable.jsx';
import { PurchaseButton } from './PurchaseButton.jsx';
import { ModalNewPurchase } from './ModalNewPurchase.jsx';
import './Book.css'


var projectTable = 'no';
var ledgerTable = 'no';

function Book(accessToken) {
  const accessT = 'Bearer ' + accessToken.accessToken;
  const [rowToEdit, setRowToEdit] = useState(null);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [showPurchaseTable, setShowPurchaseTable] = useState(false);
  const [showSalesTable, setShowSalesTable] = useState(false);
  const [modalEntryOpen, setModalEntryOpen] = useState(false);
  const [modalNewPurchaseOpen, setModalNewPurchaseOpen] = useState(false);
  const [localLedgerData, setlocalLedgerData] = useState([]);
  const [localEntryData, setLocalEntryData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalReceived, setTotalReceived] = useState(0);
  const [totalOutstanding, setTotalOutstanding] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [ledgerRow, setLedgerRow] = useState(-1);

  async function amountToINR(fetchedData) {
    var fixedData = fetchedData;
    for (let i = 0; i < fixedData.length; i++) {
      if (fixedData[i].price != null) {
        fixedData[i].price = fixedData[i].price.toLocaleString('en-IN');
        fixedData[i].outstanding = fixedData[i].outstanding.toLocaleString('en-IN');
        if (ledgerTable === 'sales') {
          fixedData[i].received = fixedData[i].received.toLocaleString('en-IN');
        } else if (ledgerTable === 'purchase') {
          fixedData[i].paid = fixedData[i].paid.toLocaleString('en-IN');
        }
      }
    }
    return fixedData;
  }

  async function setTotalAmount(fetchedData) {
    var totalPr = 0;
    var totalRec = 0;
    var totalOut = 0;
    var totalPa = 0;

    for (let i = 0; i < fetchedData.length; i++) {
      totalPr += fetchedData[i].price;
      if (ledgerTable === 'sales') {
        totalRec += fetchedData[i].received;
      } else {
        totalPa += fetchedData[i].paid;
      }
      totalOut += fetchedData[i].outstanding;
    }
    await setTotalPrice(totalPr.toLocaleString('en-IN'));
    await setTotalReceived(totalRec.toLocaleString('en-IN'));
    await setTotalPaid(totalPa.toLocaleString('en-IN'));
    await setTotalOutstanding(totalOut.toLocaleString('en-IN'));
  }

  async function formatDateAddDash(oldDate) {
    var dd = oldDate.substring(0, 2);
    var mm = oldDate.substring(2, 4);
    var yyyy = oldDate.substring(4);
    var newDate = dd + '-' + mm + '-' + yyyy;
    return newDate;
  }

  async function setRecAndOutAmount(fetchData) {
    for (let i = 0;  i < fetchData.length; i++) {
      fetchData[i].received = 0;
      fetchData[i].outstanding = fetchData[i].price;
    }
    var tableAddress = process.env.REACT_APP_GET_SALES_ENTRIES_PROJECT + projectTable;
    const res = await fetch(tableAddress, {
      headers: {
        Authorization: accessT
      },
      method: 'GET'
    });
    const fetchedEntryData = await res.json();
    for (let k = 0; k < fetchedEntryData.length; k++) {
      for(let j = 0; j < fetchData.length; j++) {
        if(fetchedEntryData[k].unit === fetchData[j].unit) {
          fetchData[j].received += fetchedEntryData[k].amount;
          fetchData[j].outstanding -= fetchedEntryData[k].amount;
          break;
        }
      }
    }
    return fetchData;
  }

  async function setPaidAndOutAmount(fetchData) {
    for (let i = 0;  i < fetchData.length; i++) {
      fetchData[i].paid = 0;
      fetchData[i].outstanding = fetchData[i].price;
    }
    var tableAddress = process.env.REACT_APP_GET_PURCHASE_ENTRIES_PROJECT + projectTable;
    const res = await fetch(tableAddress, {
      headers: {
        Authorization: accessT
      },
      method: 'GET'
    });
    const fetchedEntryData = await res.json();
    for (let i = 0; i < fetchedEntryData.length; i++) {
      for(let j = 0; j < fetchData.length; j++) {
        if(fetchedEntryData[i].id === fetchData[j].id) {
          fetchData[j].paid += fetchedEntryData[i].amount;
          fetchData[j].outstanding -= fetchedEntryData[i].amount;
          break;
        }
      }
    }
    return fetchData;
  }

  async function formatSQLDate(fetchData) {
    for (let i = 0; i < fetchData.length; i++) {
      if (fetchData[i].date !== '') {
        fetchData[i].date = await formatDateAddDash(fetchData[i].date);
      }
    }
    return fetchData
  }

  async function fetchLedgerData() {
    var tableAddress = '';
    if (ledgerTable === 'sales') {
      tableAddress = process.env.REACT_APP_GET_PROJECT + projectTable;
    } else if (ledgerTable === 'purchase') {
      tableAddress = process.env.REACT_APP_GET_PURCHASES + projectTable;
    }
    const res = await fetch(tableAddress, {
      headers: {
        Authorization: accessT 
      },
      method: 'GET'
    })
    var fetchData = await res.json();
    if (ledgerTable === 'sales') {
      fetchData = await setRecAndOutAmount(fetchData);
    } else if (ledgerTable === 'purchase') {
      fetchData = await setPaidAndOutAmount(fetchData);
    }
    fetchData = await formatSQLDate(fetchData);
    await setTotalAmount(fetchData);
    fetchData = await amountToINR(fetchData);
    await setlocalLedgerData(fetchData);
  }

  async function fetchEntryData(tableKey) {
    var tableAddress = '';
    if (ledgerTable === 'sales') {
      tableAddress = process.env.REACT_APP_GET_SALES_ENTRIES + projectTable + '/' + tableKey;
    } else if (ledgerTable === 'purchase') {
      tableAddress = process.env.REACT_APP_GET_PURCHASE_ENTRIES + projectTable + '/' + tableKey;
    }
    const res = await fetch(tableAddress, {
      headers: {
        Authorization: accessT 
      },
      method: 'GET'
    })
    var fetchData = await res.json();
    fetchData = await formatSQLDate(fetchData);
    await setLocalEntryData(fetchData);
  }

  async function fetchUpdateProject(newRow) {
    var tableAddress = process.env.REACT_APP_POST_UPDATE_PROJECT;
    const res = await fetch(tableAddress, {
      headers: {
        Authorization: accessT,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(newRow)
    })
  }

  async function fetchUpdatePurchase(newRow) {
    var tableAddress = process.env.REACT_APP_POST_UPDATE_PURCHASE;
    const res = await fetch(tableAddress, {
      method: 'POST',
      headers: {
        Authorization: accessT,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(newRow)
    })
  }

  async function fetchLastID(IDtype) {
    var tableAddress = process.env.REACT_APP_GET_IDGEN + IDtype;
    const res = await fetch(tableAddress, {
      headers: {
        Authorization: accessT 
      },
      method: 'GET'
    })
    const fetchData = await res.json();
    return fetchData;
  }

  async function fetchUpdateIDGEN(IDtype, newID) {
    var tableAddress = process.env.REACT_APP_POST_UPDATE_IDGEN + IDtype + '/' + newID;
    const res = await fetch(tableAddress, {
      headers: {
        Authorization: accessT 
      },
      method: 'POST'
    })
  }

  async function fetchAddEntry(entryType, newEntry) {
    const IDGEN = await fetchLastID(entryType);
    const lastID = IDGEN[0].id;
    const newID = lastID + 1;
    newEntry.bill = newID;
    var tableAddress = '';
    if (entryType === 'sales') {
      tableAddress = process.env.REACT_APP_POST_ADD_SALES_ENTRY;
    } else if (entryType === 'p_entry') {
      tableAddress = process.env.REACT_APP_POST_ADD_PURCHASE_ENTRY;
    }
    const res = await fetch(tableAddress, {
      method: 'POST',
      headers: {
        Authorization: accessT, 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(newEntry)
    })
    await fetchUpdateIDGEN(entryType, newID);
  }

  async function fetchUpdateEntry(entryType, newEntry) {
    var tableAddress = '';
    if (entryType === 'sales') {
      tableAddress = process.env.REACT_APP_POST_UPDATE_SALES_ENTRY;
    } else if (entryType === 'p_entry') {
      tableAddress = process.env.REACT_APP_POST_UPDATE_PURCHASE_ENTRY;
    }
    const res = await fetch(tableAddress, {
      method: 'POST',
      headers: {
        Authorization: accessT,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(newEntry)
    })
  }

  async function fetchDeleteEntry(entryType, bill) {
    var tableAddress = '';
    if (entryType === 'sales') {
      tableAddress = process.env.REACT_APP_POST_DELETE_SALES_ENTRY + bill;
    } else if (entryType === 'purchase') {
      tableAddress = process.env.REACT_APP_POST_DELETE_PURCHASE_ENTRY_BILL + bill;
    }
    const res = await fetch(tableAddress, {
      headers: {
        Authorization: accessT 
      },
      method: 'POST'
    })
  }

  async function fetchDeletePurchaseEntries(id) {
    var tableAddress = process.env.REACT_APP_POST_DELETE_PURCHASE_ENTRY_ID + projectTable + '/' + id;
    const res = await fetch(tableAddress, {
      headers: {
        Authorization: accessT 
      },
      method: 'POST'
    })
  }

  async function fetchDeletePurchase(id) {
    var tableAddress = process.env.REACT_APP_POST_DELETE_PURCHASE + id;
    const res = await fetch(tableAddress, {
      headers: {
        Authorization: accessT 
      },
      method: 'POST'
    })
  }

  async function fetchAddPurchase(newPurchase) {
    const IDGEN = await fetchLastID('purchase');
    const lastID = IDGEN[0].id;
    const newID = lastID + 1;
    newPurchase.id = newID;
    var tableAddress = process.env.REACT_APP_POST_ADD_PURCHASE;
    const res = await fetch(tableAddress, {
      method: 'POST',
      headers: {
        Authorization: accessT, 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(newPurchase)
    })
    await fetchUpdateIDGEN('purchase', newID);
    await fetchLedgerData();
  }

  async function fetchDeleteUnit(unitnum) {
    var tableAddress = process.env.REACT_APP_POST_CLEAR_PROJECT_UNIT + projectTable + "/" + unitnum;
    const res = await fetch(tableAddress, {
      headers: {
        Authorization: accessT 
      },
      method: 'POST'
    })
  }

  async function fetchDeleteUnitEntries(unitnum) {
    var tableAddress = process.env.REACT_APP_POST_DELETE_UNIT_ENTRY + projectTable + "/" + unitnum;
    const res = await fetch(tableAddress, {
      headers: {
        Authorization: accessT 
      },
      method: 'POST'
    })
  }

  async function handleEditSubmit(newRow) {
    newRow.price = await newRow.price.replaceAll(',', '');
    newRow.date = await newRow.date.replaceAll('-', '');
    if (ledgerTable === 'sales') {
      newRow.project = projectTable;
      await fetchUpdateProject(newRow);
    } else if (ledgerTable === 'purchase') {
      await fetchUpdatePurchase(newRow);
    }
    await fetchLedgerData();
  }

  async function handleEntrySubmit(newEntry) {
    newEntry.amount = await newEntry.amount.replaceAll(',', '');
    newEntry.date = await newEntry.date.replaceAll('-', '');
    if (ledgerTable === 'sales') {
      if(newEntry.bill !== undefined){
        await fetchUpdateEntry('sales', newEntry);
      }else{
        await fetchAddEntry('sales', newEntry);
      }
      await fetchEntryData(localLedgerData[ledgerRow].unit);
    } else if (ledgerTable === 'purchase') {
      if(newEntry.bill !== undefined){
        await fetchUpdateEntry('p_entry', newEntry);
      }else{
        await fetchAddEntry('p_entry', newEntry);
      }
      await fetchEntryData(localLedgerData[ledgerRow].id);
    }
    await fetchLedgerData();
  }

  async function handleProjectSelect(tableName) {
    projectTable = tableName;
    if (projectTable !== 'no' && ledgerTable === 'sales') {
      await fetchLedgerData();
      await setShowPurchaseTable(false);
      await setShowSalesTable(true);
    } else if (projectTable !== 'no' && ledgerTable === 'purchase') {
      await fetchLedgerData();
      await setShowSalesTable(false);
      await setShowPurchaseTable(true);
    } else {
      await setShowPurchaseTable(false);
      await setShowSalesTable(false);
    }
  }

  async function handleLedgerSelect(tableName) {
    ledgerTable = tableName;
    if (projectTable !== 'no' && ledgerTable === 'sales') {
      await fetchLedgerData();
      await setShowPurchaseTable(false);
      await setShowSalesTable(true);
    } else if (projectTable !== 'no' && ledgerTable === 'purchase') {
      await fetchLedgerData();
      await setShowSalesTable(false);
      await setShowPurchaseTable(true);
    } else {
      await setShowPurchaseTable(false);
      await setShowSalesTable(false);
    }
  }

  async function handleEditRow(idx) {
    await setRowToEdit(idx);
    await setShowSalesTable(false);
    await setShowPurchaseTable(false);
    await setModalEditOpen(true);
  }

  async function handleEditEntry(idx) {
    if (localLedgerData[idx].price !== null) {
      await setRowToEdit(idx);
      if (ledgerTable === 'sales') {
        await fetchEntryData(localLedgerData[idx].unit);
        await setLedgerRow(idx);
        await setShowSalesTable(false);
      } else if (ledgerTable === 'purchase') {
        await fetchEntryData(localLedgerData[idx].id)
        await setLedgerRow(idx);
        await setShowPurchaseTable(false);
      }
      await setModalEntryOpen(true);
    }
  }

  async function handleNewPurchaseButton() {
    await setShowPurchaseTable(false);
    await setModalNewPurchaseOpen(true);
  }

  async function handleNewPurchaseSubmit(newPurchase) {
    newPurchase.price = await newPurchase.price.replaceAll(',', '');
    newPurchase.date = await newPurchase.date.replaceAll('-', '');
    await fetchAddPurchase(newPurchase);
  }

  async function handleDeleteEntry(billNum) {
    await fetchDeleteEntry(ledgerTable, billNum);
    await fetchLedgerData();
    if (ledgerTable === 'sales') {
      await fetchEntryData(localLedgerData[ledgerRow].unit);
    } else if (ledgerTable === 'purchase') {
      await fetchEntryData(localLedgerData[ledgerRow].id)
    }
  }

  async function handleDeleteUnit(unitnum) {
    await fetchDeleteUnitEntries(unitnum);
    await fetchDeleteUnit(unitnum);
    await fetchLedgerData();
  }

  async function handleDeletePurchase(id) {
    await setShowPurchaseTable(false);
    await fetchDeletePurchaseEntries(id);
    await fetchDeletePurchase(id);
    await fetchLedgerData();
    await setShowPurchaseTable(true);
  }

  return (
    <div className='Book'>
      <Select selectedProject={handleProjectSelect} />
      <LedgerSelect selectedLedger={handleLedgerSelect} />
      {showSalesTable && (
        <SalesTable projectName={projectTable} rows={localLedgerData} editRow={handleEditRow} editEntry={handleEditEntry} totalPr={totalPrice} totalAmt={totalReceived} totalOut={totalOutstanding} />
      )}
      {showPurchaseTable && (
        <PurchaseButton projectName={projectTable} addPurchase={handleNewPurchaseButton} />
      )}
      {showPurchaseTable && (
        <PurchaseTable projectName={projectTable} rows={localLedgerData} editRow={handleEditRow} editEntry={handleEditEntry} deleteEntry={handleDeletePurchase} totalPr={totalPrice} totalPd={totalPaid} totalOut={totalOutstanding} />
      )}
      {modalEditOpen && (
        <ModalEdit
          projectName={projectTable}
          ledger={ledgerTable}
          closeModalEdit={() => {
            setModalEditOpen(false);
            setRowToEdit(null);
            if (ledgerTable === 'sales') {
              setShowSalesTable(true);
            } else if (ledgerTable === 'purchase') {
              setShowPurchaseTable(true);
            }
          }}
          onEditSubmit={handleEditSubmit}
          defaultEditValue={localLedgerData[rowToEdit]}
          deleteUnit={handleDeleteUnit}
          deletePurchase={handleDeletePurchase}
        />
      )}
      {modalEntryOpen && (
        <ModalEntry
          ledger={ledgerTable}
          tableData={localLedgerData[rowToEdit]}
          entryData={localEntryData}
          projectName={projectTable}
          onEntrySubmit={handleEntrySubmit}
          deleteEntry={handleDeleteEntry}
          closeModalEntry={() => {
            setModalEntryOpen(false);
            setRowToEdit(null);
            if (ledgerTable === 'sales') {
              setShowSalesTable(true);
            } else if (ledgerTable === 'purchase') {
              setShowPurchaseTable(true);
            }
          }}
        />
      )}
      {modalNewPurchaseOpen && (
        <ModalNewPurchase
          projectName={projectTable}
          onEditSubmit={handleNewPurchaseSubmit}
          closeModalNewPurchase={() => {
            setModalNewPurchaseOpen(false);
            setShowPurchaseTable(true);
          }}
        />
      )}
    </div>
  )
}

export default Book