import React, { useState } from 'react'
import './ModalEntry.css'
import { RiDeleteBin6Line } from 'react-icons/ri'

export const ModalEntry = ({ ledger, tableData, entryData, projectName, onEntrySubmit, deleteEntry, closeModalEntry }) => {
  // const [formState, setFormState] = useState({
  //   date: "",
  //   amount: "",
  //   remarks: "",
  // });

  const [, forceRender] = useState(undefined);

  function projectNametoString() {
    if (projectName === 'nr') {
      return 'Noor Residency';
    } else if (projectName === 'gh') {
      return 'Galaxy Homes';
    } else if (projectName === 'bm') {
      return 'Baage Madina';
    } else if (projectName === 'uh') {
      return 'Usmania Homes';
    } else if (projectName === 'rr') {
      return 'Rayyan Residency';
    } else if (projectName === 'dv') {
      return 'Dream Villa';
    } else if (projectName === 'fha') {
      return 'Firdos Heights A';
    } else if (projectName === 'fhb') {
      return 'Firdos Heights B';
    } else if (projectName === 'fhc') {
      return 'Firdos Heights C';
    } else if (projectName === 'fhd') {
      return 'Firdos Heights D';
    } else if (projectName === 'fhe') {
      return 'Firdos Heights E';
    } else if (projectName === 'fhs') {
      return 'Firdos Heights S';
    }
  }

  for (let i = 0; i < entryData.length; i++) {
    entryData[i].amount = entryData[i].amount.toLocaleString('en-IN');
  }

  function handleUpdate(eBill, eDate, eAmount, eRemarks) {
    document.getElementById('update-bill-label').style.display = "block";
    document.getElementById('update-bill-label').innerHTML = "Update info for bill: " + eBill;
    document.getElementById("submit-date").value = eDate;
    document.getElementById("submit-amount").value = eAmount;
    document.getElementById("submit-remarks").value = eRemarks;
  }

  function handleDelete(eBill) {
    deleteEntry(eBill);
    forceRender((prev) => !prev);
  }

  function checkEntry(finalEntry) {
    if (finalEntry.date === "" ||
      !/\S/.test(finalEntry.date) ||
      finalEntry.amount === "" ||
      !/\S/.test(finalEntry.amount) ||
      finalEntry.remarks === "" ||
      !/\S/.test(finalEntry.remarks)) {
      return false;
    }
    return true;
  }

  // const handleChange = (e) => {
  //   setFormState({
  //     ...formState,
  //     [e.target.name]: e.target.value
  //   });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    var labelEle = document.getElementById('update-bill-label');
    var updatedEntry = {
      date: document.getElementById("submit-date").value,
      amount: document.getElementById("submit-amount").value,
      remarks: document.getElementById("submit-remarks").value
    }
    if (labelEle.style.display === "block") {
      labelEle.style.display = "none";
      const billNum = labelEle.innerHTML.split(" ")[4];
      updatedEntry.bill = billNum;
    } else {
      updatedEntry.project = projectName;
      if (ledger === 'sales') {
        updatedEntry.unit = tableData.unit;
      } else if (ledger === 'purchase') {
        updatedEntry.id = tableData.id;
      }
    }
    if (checkEntry(updatedEntry)) {
      onEntrySubmit(updatedEntry);
      document.getElementById("submit-date").value = '';
      document.getElementById("submit-amount").value = '';
      document.getElementById("submit-remarks").value = '';
      forceRender((prev) => !prev);
    }
  };

  function convertDate(d){
    var p = d.split("-");
    return +(p[2]+p[1]+p[0]);
  }
  const handleSortByDate = (e) => {
    entryData.sort(function(a,b) {
      return convertDate(a.date) - convertDate(b.date);
    });
    forceRender((prev) => !prev);
  };

  return (
    <div className='entry-container' onClick={(e) => { if (e.target.className === 'entry-container') closeModalEntry(); }}>
      <div className='entry-modal'>

        {ledger === 'sales' ? (
          <div className='entry-info-container'>
            <div className='label-group'>
              <label>{projectNametoString() + ': ' + tableData.unit}</label>
              <label>{'Booked on: ' + tableData.date}</label>
            </div>
            <div className='label-group'>
              <label>{tableData.name}</label>
              <label>{'Phone: ' + tableData.phone}</label>
            </div>
            <div className='label-group'>
              <label>{'Broker: ' + tableData.broker}</label>
              <label>{'Price: ' + tableData.price}</label>
            </div>
            <label className='label-remarks'>{tableData.remarks}</label>
          </div>
        ) : (
          <div className='entry-info-container'>
            <div className='label-group'>
              <label>{tableData.name}</label>
              <label>{'Phone: ' + tableData.phone}</label>
            </div>
            <div className='label-group'>
              <label>{tableData.product}</label>
              <label>{'Price: ' + tableData.price}</label>
            </div>
            <div className='label-group'>
              <label className='label-remarks'>{tableData.remarks}</label>
              <label>{'Purchased on: ' + tableData.date}</label>
            </div>
          </div>
        )}

        <div className='entry-table-container'>
          <table className='entry-table'>
            <thead>
              <tr>
                <th className='bill-column'>Bill</th>
                <th className='date-column sort-date-btn' onClick={handleSortByDate}>Date</th>
                <th className='entry-table-head-amount amount-column'>Amount</th>
                <th>Remarks</th>
                <th className='delete-column'></th>
              </tr>
            </thead>
            <tbody>
              {entryData.map((entry, idx) => {
                return (
                  <tr key={idx}>
                    <td className='bill-column edit-btn' onClick={() => handleUpdate(entry.bill, entry.date, entry.amount, entry.remarks)}>{entry.bill}</td>
                    <td className='date-column'>{entry.date}</td>
                    <td className='amount-entry amount-column'>{entry.amount}</td>
                    <td>{entry.remarks}</td>
                    <td className='delete-column' onClick={() => handleDelete(entry.bill)}><RiDeleteBin6Line className='delete-btn' /></td>
                  </tr>
                );
              })}
            </tbody>
            {ledger === 'sales' ? (
              <tfoot>
                <tr className='foot-amounts'>
                  <td className='bill-column'></td>
                  <td className='date-column'>Received:</td>
                  <td className='amount-entry amount-column'>{tableData.received}</td>
                  <td></td>
                  <td className='delete-column'></td>
                </tr>
                <tr className='foot-amounts'>
                  <td className='bill-column'></td>
                  <td className='date-column'>Outstanding:</td>
                  <td className='amount-entry amount-column'>{tableData.outstanding}</td>
                  <td></td>
                  <td className='delete-column'></td>
                </tr>
              </tfoot>
            ) : (
              <tfoot>
                <tr className='foot-amounts'>
                  <td className='bill-column'></td>
                  <td className='date-column'>Paid:</td>
                  <td className='amount-entry amount-column'>{tableData.paid}</td>
                  <td></td>
                  <td className='delete-column'></td>
                </tr>
                <tr className='foot-amounts'>
                  <td className='bill-column'></td>
                  <td className='date-column'>Outstanding:</td>
                  <td className='amount-entry amount-column'>{tableData.outstanding}</td>
                  <td></td>
                  <td className='delete-column'></td>
                </tr>
              </tfoot>
            )}
          </table>
          {/* <div className='entry-table-wrapper'>
            
          </div> */}
        </div>

        <div className='entry-submit-container'>
          <div className='submit-group'>
            <label id='update-bill-label'></label>
          </div>
          <div className='submit-group'>
            <label>Date</label>
            <input name='date' id='submit-date' className='foot-submit-date' placeholder='ddmmyyyy' />
          </div>
          <div className='submit-group'>
            <label>Amount</label>
            <input name='amount' id='submit-amount' className='foot-submit-amount' placeholder='0,00,000' />
          </div>
          <div className='submit-group'>
            <label>Remarks</label>
            <input name='remarks' id='submit-remarks' className='foot-submit-remarks' placeholder='new entry remarks' />
          </div>
          <div className='submit-group'>
            <button type='submit' className='foot-submit-btn' onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  )
}