import React, { useState } from 'react';
import './Select.css'

export const Select = ({selectedProject}) => {
  const [showSubSelect, setShowSubSelect] = useState(false);

  function checkAlert(evt){
    if(evt.target.value === "Select a project"){
      setShowSubSelect(false);
      selectedProject('no');
    }else if(evt.target.value === "Noor Residency"){
      setShowSubSelect(false);
      selectedProject('nr');
    }else if(evt.target.value === "Galaxy Homes"){
      setShowSubSelect(false);
      selectedProject('gh');
    }else if(evt.target.value === "Baage Madina"){
      setShowSubSelect(false);
      selectedProject('bm');
    }else if(evt.target.value === "Usmania Homes"){
      setShowSubSelect(false);
      selectedProject('uh');
    }else if(evt.target.value === "Rayyan Residency"){
      setShowSubSelect(false);
      selectedProject('rr');
    }else if(evt.target.value === "Dream Villa"){
      setShowSubSelect(false);
      selectedProject('dv');
    }else if(evt.target.value === "Firdos Heights"){
      setShowSubSelect(true);
      selectedProject('no');
    }
  }

  function checkSubAlert(evt) {
    if(evt.target.value === "Select a tower"){
      selectedProject('no');
    }else if(evt.target.value === "Tower A"){
      selectedProject('fha');
    }else if(evt.target.value === "Tower B"){
      selectedProject('fhb');
    }else if(evt.target.value === "Tower C"){
      selectedProject('fhc');
    }else if(evt.target.value === "Tower D"){
      selectedProject('fhd');
    }else if(evt.target.value === "Tower E"){
      selectedProject('fhe');
    }else if(evt.target.value === "Shops"){
      selectedProject('fhs');
    }  
  }


  return (
    <div className='select-container'>
        <label>Project</label>
        <select name='status' onChange={e => checkAlert(e)}>
            <option>Select a project</option>
            <option>Noor Residency</option>
            <option>Galaxy Homes</option>
            <option>Baage Madina</option>
            <option>Usmania Homes</option>
            <option>Rayyan Residency</option>
            <option>Dream Villa</option>
            <option>Firdos Heights</option>
        </select>
        {showSubSelect && (
          <select onChange={e => checkSubAlert(e)}>
            <option>Select a tower</option>
            <option>Tower A</option>
            <option>Tower B</option>
            <option>Tower C</option>
            <option>Tower D</option>
            <option>Tower E</option>
            <option>Shops</option>
          </select>
        )}
    </div>
  );
}
