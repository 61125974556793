import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Userfront, { SignupForm, LoginForm, LogoutButton } from '@userfront/toolkit/react';
import Book from './components/Book';
import './App.css'

Userfront.init("rbv7v77n");

function App() {
  return (
    <div className='app-wapper'>
      <div className='logout-button-wrapper'>
        {Userfront.tokens.accessToken && (<LogoutButton />)}
      </div>
      <Router>
        <div>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/book' element={<RequireAuth><Book accessToken={Userfront.accessToken()} /></RequireAuth>} />
          </Routes>
        </div>
      </Router>
    </div>
  )
}

const Home = () => (
  <div>
    {/* <SignupForm /> */}
    <LoginForm />
  </div>
);

const RequireAuth = ({ children }) => {
  let location = useLocation();
  if (!Userfront.tokens.accessToken) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
}

export default App