import React from 'react';
import './LedgerSelect.css'

export const LedgerSelect = ({selectedLedger}) => {
  function checkAlert(evt){
    if(evt.target.value === "Select a ledger"){
        selectedLedger('no');
    }else if(evt.target.value === "Sales"){
        selectedLedger('sales');
    }else if(evt.target.value === "Purchase"){
        selectedLedger('purchase');
    }
  }
  return (
    <div className='ledger-select-container'>
        <label>Ledger</label>
        <select name='status' onChange={e => checkAlert(e)}>
            <option>Select a ledger</option>
            <option>Sales</option>
            <option>Purchase</option>
        </select>
    </div>
  );
}
