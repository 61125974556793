import React, { useState } from 'react'
import './ModalNewPurchase.css'

export const ModalNewPurchase = ({projectName, onEditSubmit, closeModalNewPurchase}) => {
  const [formState, setFormState] = useState({
    name:"",
    phone:"",
    product:"",
    date:"",
    price:"",
    remarks:""
  });


  var projectLabel = 'no';
  if (projectName === 'nr') {
    projectLabel = 'Noor Residency';
  } else if (projectName === 'gh') {
    projectLabel = 'Galaxy Homes';
  } else if (projectName === 'bm') {
    projectLabel = 'Baage Madina';
  } else if (projectName === 'rr') {
    projectLabel = 'Rayyan Residency';
  } else if (projectName === 'dv') {
    projectLabel = 'Dream Villa';
  } else if (projectName === 'uh') {
    projectLabel = 'Usmania Homes';
  } else if (projectName === 'fh') {
    projectLabel = 'Firdos Heights';
  }

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formState.project = projectName;
    onEditSubmit(formState);
    closeModalNewPurchase();
  };

  return (
    <div
      className='modal-newpurchase-container'
      onClick={(e) => {
        if (e.target.className === 'modal-newpurchase-container') closeModalNewPurchase();
      }}
    >
      <div className='modal-newpurchase'>
        <form>
          <div className='newpurchase-form-group'>
            <label className='newpurchase-header-label'>{"Add new purchase in " + projectLabel}</label>
          </div>
          <div className='newpurchase-form-group'>
            <label>Name</label>
            <input name='name' onChange={handleChange} />
          </div>
          <div className='newpurchase-form-group'>
            <label>Phone</label>
            <input name='phone' onChange={handleChange} />
          </div>
          <div className='newpurchase-form-group'>
            <label>Product</label>
            <input name='product' onChange={handleChange} />
          </div>
          <div className='newpurchase-form-group'>
            <label>Date</label>
            <input name='date' onChange={handleChange} />
          </div>
          <div className='newpurchase-form-group'>
            <label>Price</label>
            <input name='price' onChange={handleChange} />
          </div>
          <div className='newpurchase-form-group'>
            <label>Remarks</label>
            <input name='remarks' onChange={handleChange} />
          </div>
          <button type='submit' className='newpurchase-btn' onClick={handleSubmit}>Submit</button>
        </form>
      </div>
    </div>
  )
}
