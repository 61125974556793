import React from 'react';
import './SalesTable.css';
import { RiPrinterLine } from "react-icons/ri";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export const SalesTable = ({ projectName, rows, editRow, editEntry, totalPr, totalAmt, totalOut }) => {
    function handlePrint() {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const formattedToday = dd + '-' + mm + '-' + yyyy;

        var projectLabel = '';
        var fileName = '';
        switch (projectName) {
            case 'nr':
                projectLabel = 'Noor Residency';
                fileName = 'Noor';
                break;
            case 'gh':
                projectLabel = 'Galaxy Homes';
                fileName = 'Galaxy';
                break;
            case 'bm':
                projectLabel = 'Baag-E-Madina';
                fileName = 'BaagEMadina';
                break;
            case 'uh':
                projectLabel = 'Usmania Homes';
                fileName = 'Usmania';
                break;
            case 'rr':
                projectLabel = 'Rayyan Residency';
                fileName = 'Rayyan';
                break;
            case 'dv':
                projectLabel = 'Dream Villa';
                fileName = 'Dream';
                break;
            case 'fha':
                projectLabel = 'Firdos Heights - A';
                fileName = 'FirdosA';
                break;
            case 'fhb':
                projectLabel = 'Firdos Heights - B';
                fileName = 'FirdosB';
                break;
            case 'fhc':
                projectLabel = 'Firdos Heights - C';
                fileName = 'FirdosC';
                break;
            case 'fhd':
                projectLabel = 'Firdos Heights - D';
                fileName = 'FirdosD';
                break;
            case 'fhe':
                projectLabel = 'Firdos Heights - E';
                fileName = 'FirdosE';
                break;
            case 'fhs':
                projectLabel = 'Firdos Heights - Shops';
                fileName = 'FirdosShops';
                break;
        }
        fileName = fileName + dd + mm + yyyy;

        var dataToPrint = [];
        for (let i = 0; i < rows.length; i++) {
            var tempRow = rows[i];
            tempRow.price = (Number(tempRow.price.replaceAll(',', '')) / 1e5).toFixed(2);
            tempRow.received = (Number(tempRow.received.replaceAll(',', '')) / 1e5).toFixed(2);
            tempRow.outstanding = (Number(tempRow.outstanding.replaceAll(',', '')) / 1e5).toFixed(2);
            if (tempRow.price === '0.00') {
                tempRow.price = '';
            }
            if (tempRow.received === '0.00') {
                tempRow.received = '';
            }
            if (tempRow.outstanding === '0.00') {
                tempRow.outstanding = '';
            }
            var target = Object.assign({}, tempRow);
            delete target.remarks;
            dataToPrint[i] = Object.values(target);
        }

        var totPrice = (Number(totalPr.replaceAll(',', '')) / 1e5).toFixed(2);
        var totAmount = (Number(totalAmt.replaceAll(',', '')) / 1e5).toFixed(2);
        var totOutstanding = (Number(totalOut.replaceAll(',', '')) / 1e5).toFixed(2);

        dataToPrint.push(['', '', '', '', 'TOTAL', totPrice, totAmount, totOutstanding]);

        var printTableLabel = projectLabel + " : " + formattedToday;
        var headers = [["Plot", "Name", "Broker", "Date", "Phone", "Price", "Rec.", "Out."]];
        var doc = new jsPDF("p", "pt", "a4", true);
        doc.text(printTableLabel, (doc.internal.pageSize.width / 2), 30, { align: 'center' });
        doc.autoTable({
            head: headers,
            body: dataToPrint,
            theme: 'grid',
            styles: { overflow: 'linebreak', cellWidth: 'wrap', lineWidth: 1, cellPadding: 2, fontSize: 10, halign: 'center' },
            headStyles: { fillColor: '#FFFFFF', textColor: [0, 0, 0] },
            columnStyles: { text: { cellWidth: 'auto' } },

            // Properties
            startY: 40, // false (indicates margin top value) or a number
            margin: { left: 10, right: 80 }, // a number, array or object
            pageBreak: 'auto', // 'auto', 'avoid' or 'always'
            tableWidth: 'auto', // 'auto', 'wrap' or a number, 
            showHead: 'everyPage', // 'everyPage', 'firstPage', 'never',
            tableLineColor: 200, // number, array (see color section below)
            tableLineWidth: 0,
        })
        doc.save(fileName + '.pdf');
    }

    return (
        <div className='sales-table-wrapper'>
            <table className='sales-table-ledger'>
                <thead>
                    <tr>
                        <th>Unit</th>
                        <th className='td-name'>Name</th>
                        <th className='td-broker'>Broker</th>
                        <th>Booking Date</th>
                        <th>Phone</th>
                        <th className='amount'>Price</th>
                        <th className='amount'>Received</th>
                        <th className='amount'>Outstanding</th>
                        <th className='expand'>Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.map((row, idx) => {
                            return (
                                <tr key={idx}>
                                    <td className='td-unit edit-btn' onClick={() => editRow(idx)}>{row.unit}</td>
                                    <td className='td-name'>{row.name}</td>
                                    <td className='td-broker'>{row.broker}</td>
                                    <td className='td-date'>{row.date}</td>
                                    <td className='td-phone'>{row.phone}</td>
                                    <td className='amount entry-btn' onClick={() => editEntry(idx)}>{row.price}</td>
                                    <td className='amount entry-btn' onClick={() => editEntry(idx)}>{row.received}</td>
                                    <td className='amount entry-btn' onClick={() => editEntry(idx)}>{row.outstanding}</td>
                                    <td className='td-remarks expand'>{row.remarks}</td>
                                </tr>
                            );
                        })}
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total:</td>
                        <td className='amount'>{totalPr}</td>
                        <td className='amount'>{totalAmt}</td>
                        <td className='amount'>{totalOut}</td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
            <div className='print-btn-wrapper' onClick={() => handlePrint()}>
                Print <RiPrinterLine className='print-icon' />
            </div>
        </div>
    )
}
