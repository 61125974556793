import React from 'react'
import './PurchaseButton.css'

export const PurchaseButton = ({projectName, addPurchase}) => {
  var showButton = false;
  if(projectName !== 'fhb' && projectName !== 'fhc' && projectName !== 'fhd' && projectName !== 'fhe' && projectName !== 'fhs'){
    showButton = true;
  }
  return (
    <div className='purchasebutton-wrapper'>
      {showButton && (
        <button className='newpurchase-btn' onClick={() => addPurchase()}>Add New Purchase</button>
      )}
    </div>
  )
}
