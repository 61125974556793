import React, { useState } from 'react';
import './ModalEdit.css'

export const ModalEdit = ({ projectName, ledger, closeModalEdit, onEditSubmit, defaultEditValue, deleteUnit, deletePurchase }) => {

  const [formState, setFormState] = useState(defaultEditValue || {
    unit: "",
    name: "",
    broker: "",
    date: "",
    phone: "",
    price: "",
    remarks: ""
  });

  const [purchFormState, setPurchFormState] = useState(defaultEditValue || {
    name: "",
    phone: "",
    product: "",
    date: "",
    price: "",
    remarks: ""
  });

  var projectLabel = 'no';
  if (projectName === 'nr') {
    projectLabel = 'Noor Residency';
  } else if (projectName === 'gh') {
    projectLabel = 'Galaxy homes';
  } else if (projectName === 'bm') {
    projectLabel = 'Baage Madina';
  } else if (projectName === 'uh') {
    projectLabel = 'Usmania Homes';
  } else if (projectName === 'rr') {
    projectLabel = 'Rayyan Residency';
  } else if (projectName === 'dv') {
    projectLabel = 'Dream Villa';
  } else if (projectName === 'fha') {
    projectLabel = 'Firdos Heights A';
  } else if (projectName === 'fhb') {
    projectLabel = 'Firdos Heights B';
  } else if (projectName === 'fhc') {
    projectLabel = 'Firdos Heights C';
  } else if (projectName === 'fhd') {
    projectLabel = 'Firdos Heights D';
  } else if (projectName === 'fhe') {
    projectLabel = 'Firdos Heights E';
  } else if (projectName === 'fhs') {
    projectLabel = 'Firdos Heights S';
  }

  const handleChange = (e) => {
    if (ledger === 'sales') {
      setFormState({
        ...formState,
        [e.target.name]: e.target.value
      });
    } else {
      setPurchFormState({
        ...purchFormState,
        [e.target.name]: e.target.value
      })
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ledger === 'sales') {
      onEditSubmit(formState);
    } else {
      onEditSubmit(purchFormState);
    }
    closeModalEdit();
  };

  const handleDltUnit = (e) => {
    e.preventDefault();
    deleteUnit(formState.unit);
    closeModalEdit();
  }

  const handleDltPurchase = (e) => {
    e.preventDefault();
    deletePurchase(formState.id);
    closeModalEdit();
  }

  return (
    <div
      className='modal-container'
      onClick={(e) => {
        if (e.target.className === 'modal-container') closeModalEdit();
      }}
    >
      <div className='modal'>
        {ledger === 'sales' ? (
          <form>
            <div className='form-group'>
              <label className='project-label'>{projectLabel + ": " + formState.unit}</label>
              <button type='submit' className='remove-btn' onClick={handleDltUnit}>Delete Unit</button>
            </div>
            <div className='form-group'>
              <label>Name</label>
              <input name='name' value={formState.name} onChange={handleChange} />
            </div>
            <div className='form-group'>
              <label>Broker</label>
              <input name='broker' value={formState.broker} onChange={handleChange} />
            </div>
            <div className='form-group'>
              <label>Booking Date (dd-mm-yyyy)</label>
              <input name='date' value={formState.date} onChange={handleChange} />
            </div>
            <div className='form-group'>
              <label>Phone</label>
              <input name='phone' value={formState.phone} onChange={handleChange} />
            </div>
            <div className='form-group'>
              <label>Price</label>
              <input className="edit-price-amount" name='price' value={formState.price} onChange={handleChange} />
            </div>
            <div className='form-group'>
              <label>Remarks</label>
              <textarea name='remarks' value={formState.remarks} onChange={handleChange} />
            </div>
            <button type='submit' className='btn' onClick={handleSubmit}>Submit</button>
          </form>
        ) : (
          <form>
            <div className='form-group'>
              <label className='project-label'>{projectLabel + ": Purchases"}</label>
              <button type='submit' className='remove-btn' onClick={handleDltPurchase}>Delete Purchase</button>
            </div>
            <div className='form-group'>
              <label>Name</label>
              <input name='name' value={purchFormState.name} onChange={handleChange} />
            </div>
            <div className='form-group'>
              <label>Phone</label>
              <input name='phone' value={purchFormState.phone} onChange={handleChange} />
            </div>
            <div className='form-group'>
              <label>Product</label>
              <input name='product' value={purchFormState.product} onChange={handleChange} />
            </div>
            <div className='form-group'>
              <label>Date (dd-yy-mmm)</label>
              <input name='date' value={purchFormState.date} onChange={handleChange} />
            </div>
            <div className='form-group'>
              <label>Price</label>
              <input className="edit-price-amount" name='price' value={purchFormState.price} onChange={handleChange} />
            </div>
            <div className='form-group'>
              <label>Remarks</label>
              <textarea name='remarks' value={purchFormState.remarks} onChange={handleChange} />
            </div>
            <button type='submit' className='btn' onClick={handleSubmit}>Submit</button>
          </form>
        )}

      </div>
    </div>
  );
}